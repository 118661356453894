import { Form, Formik } from "formik";
import * as Yup from "yup";
import VHSImageInput from "./VHSImageInput";
import VHSInfoInputs from "./VHSInfoInputs";
import { toast } from "react-toastify";
import { createInput, createJob } from "../../logic/APIInterface";
import { useNavigate } from "react-router-dom";
import { Heading, useAuthenticator, useTheme } from "@aws-amplify/ui-react";
import { Close, CloudUploadOutlined, ExpandMore } from "@mui/icons-material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import {
  Button,
  CircularProgress,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import ImageDragAndDrop from "./ImageDragAndDrop";
import ImageInfo from "./ImageInfo";
import PatientInfo from "./PatientInfo";
import { API } from "aws-amplify";
import config from "../../config";
import { useRef, useState } from "react";
import { useAuthState } from "../../helpers/AuthProvider";
import { useModalMaker } from "../../helpers/ModalProvider";
import RedeemCoupon from "./RedeemCoupon";
import NoCouponsScreen from "./NoCouponsScreen";
import { useLanguage } from "../../helpers/LanguageProvider";
import { Link } from "react-router-dom";
import Footer from "../../helpers/Footer";

function VHSForm() {
  const navigate = useNavigate();
  const { user, logout, refreshUser, token } = useAuthState();
  const accountDropRef = useRef<HTMLDivElement>(null);
  const [dropDownActive, setDropDownActive] = useState(false);
  const modal = useModalMaker();
  const pack = useLanguage().main;
  const formPack = useLanguage().formErrors;
  const {setModal} = useModalMaker();
  if (!user)
    return (
      <h1>
        This will never be returned since user state is checked in the above
        component.
      </h1>
    );
  const usesLeft: number = user.uses.remaining;
  const subscriptions = (user as any).subscriptions as Array<{status: string}>;
  const hasActiveSubscription = subscriptions.filter(sub=>sub.status==="Active").length>0;
  return (
    <div className="w-screen min-h-screen flex flex-col sm:justify-center">
      <div className="container mx-auto border max-w-4xl rounded-md bg-white drop-shadow-lg">
        <div className="py-3 relative">
          <p className="text-center font-bold text-2xl mt-4 sm:mt-0">
            {pack.header}
          </p>
          <div className="absolute right-2 top-2 flex flex-col cursor-pointer">
            <div
              className="flex flex-row"
              ref={accountDropRef}
              onClick={() => {
                setDropDownActive(true);
              }}
            >
              <p>{pack.accountButton.label}</p>
              <span
                className={
                  "text-sm transition-transform " +
                  (dropDownActive ? "rotate-180" : "rotate-0")
                }
              >
                <ExpandMore fontSize="inherit" />
              </span>
            </div>
            <Menu
              anchorEl={accountDropRef.current}
              open={dropDownActive}
              onClose={() => {
                setDropDownActive(false);
              }}
            >
              <Link to="/history">
                <MenuItem>{pack.accountButton.history}</MenuItem>
              </Link>
              <MenuItem
                onClick={() => {
                  setDropDownActive(false);
                  setModal(<RedeemCoupon onSuccess={()=>{
                    refreshUser();
                    toast.success("Successfully redeemed coupon!");
                  }} />)
                }}
              >
                {pack.accountButton.redeem}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  logout();
                }}
              >
                {pack.accountButton.signOut}
              </MenuItem>
            </Menu>
          </div>
          {usesLeft >= 0 && (
            <p className="text-center text-xl font-sans mb-2">
              <span
                dangerouslySetInnerHTML={{
                  __html: pack.usesIndicator(usesLeft,hasActiveSubscription),
                }}
                className="inline"
              ></span>{" "}
              {usesLeft > 0 && (
                <>
                  <button
                    className="appearance-none underline underline-offset-1 text-brand-100 hover:text-brand-80 hover:underline-offset-2 transition hover:font-medium"
                    onClick={(e) => {
                      e.preventDefault();
                      modal.setModal(
                        <RedeemCoupon
                          onSuccess={() => {
                            refreshUser();
                            toast.success(pack.redeemSucessToast);
                            modal.setModal(null);
                          }}
                        />
                      );
                    }}
                  >
                    {pack.getMoreButton}
                  </button>
                  .
                </>
              )}
            </p>
          )}
          {usesLeft === -1 && (
            <p className="text-center text-xl font-sans mb-2">
              {pack.usesIndicator(5,false).split("<b>5</b>")[0]}
              <CircularProgress size={12} />
              {pack.usesIndicator(5,false).split("<b>5</b>")[1]}
            </p>
          )}

          {(usesLeft !== 0 || hasActiveSubscription) && (
            <Formik
              initialValues={{
                animal: "",
                breed: "",
                date: null,
                file: null,
                owner: "",
                patientId: "",
              }}
              validationSchema={Yup.object({
                owner: Yup.string().required(formPack.required),
                animal: Yup.string().required(formPack.required),
                date: Yup.date().nullable().required(formPack.required),
                file: Yup.mixed()
                  .required(formPack.required)
                  .test("fileSize", formPack.fileTooLarge, (value) => {
                    if (!value || !value.size) return false;
                    return value.size <= 1024 * 1024 * 50;
                  })
                  .test("type", formPack.fileWrongFormat, (value) => {
                    if (value && value.name.endsWith(".dcm")) return true;
                    if (!value || !value.type) return false;
                    return config.accepts.split(",").includes(value.type);
                  }),
              })}
              onSubmit={async (values, { setSubmitting }) => {
                setSubmitting(true);
                if (token) {
                  const prom = createInput(values.file!, token);
                  prom.catch((err) => {
                    toast.error(err);
                    setSubmitting(false);
                  });
                  prom.then((id) => {
                    const createJobProm = createJob(values, id, token);
                    createJobProm.then((jobId) => {
                      refreshUser();
                      navigate(`job/${jobId}`);
                    });
                    createJobProm.catch((err) => {
                      toast.error(err);
                    });
                    createJobProm.finally(() => {
                      setSubmitting(false);
                    });
                  });
                }
              }}
            >
              {(formik) => (
                <div className="flex flex-row py-4 px-16 flex-wrap gap-x-16 gap-y-1">
                  <ImageInfo />
                  <div className="w-full sm:flex-1 h-80 divide-y-2 flex flex-col gap-y-2">
                    <PatientInfo />
                    <Button
                      fullWidth
                      onClick={formik.submitForm}
                      disabled={
                        formik.isSubmitting ||
                        !(formik.isValid && formik.dirty) ||
                        (usesLeft <= 0 && !hasActiveSubscription)
                      }
                      variant="contained"
                      sx={{ textTransform: "none" }}
                      className="grow"
                    >
                      {pack.analyzeButton}
                    </Button>
                  </div>
                </div>
              )}
            </Formik>
          )}

          {usesLeft === 0 && !hasActiveSubscription && <NoCouponsScreen />}
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default VHSForm;
