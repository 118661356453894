import { Heading } from "@aws-amplify/ui-react";
import { Home } from "@mui/icons-material";
import { Button, Fab, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { useLanguage } from "../../helpers/LanguageProvider";
import LoginForm from "../AuthScreen/LoginForm";
import { DataGrid } from "@mui/x-data-grid";
import { getDate } from "../jobs/ReportViewer";
import { useEffect, useState } from "react";
import { getJobs } from "../../logic/APIInterface";
import { CompletedJob, CompletedJobError, InProgressJob } from "../../types";
import { useAuthState } from "../../helpers/AuthProvider";
import Footer from "../../helpers/Footer";

type Row = {
  id: number;
  owner: string;
  animal: string;
  date: string;
  jobId: string;
};

function JobToDataRow(
  job: CompletedJob | InProgressJob | CompletedJobError,
  id: number
): Row | null {
  if (job.metadata) {
    const meta = job.metadata as any;
    if (meta.owner && meta.animal && meta.date) {
      return {
        id,
        animal: meta.animal,
        owner: meta.owner,
        date: meta.date,
        jobId: job.id,
      };
    }
  }
  return null;
}

function HistoryPage() {
  const [jobs, setJobs] = useState<
    Array<CompletedJob | InProgressJob | CompletedJobError>
  >([]);

  const { token } = useAuthState();

  useEffect(() => {
    if (token) {
      getJobs(token).then((res) => {
        setJobs(res.filter((job) => job.config.action === "bIjob" || job.config.action=="reSubmitPoints"));
      });
    }
  }, [token]);

  const pack = useLanguage().history;
  return (
    <div className="w-[100%] min-h-screen flex flex-col justify-center items-center">
      <div className="container mx-auto border max-w-4xl rounded-md bg-white drop-shadow-lg">
        <div className="py-3 relative">
          <Heading level={3} textAlign={"center"}>
            {pack.header}
          </Heading>
        </div>
        <div className="h-fit px-4 pb-2">
          <DataGrid
            initialState={{
              sorting: {
                sortModel: [{ field: "date", sort: "desc" }],
              },
            }}
            isRowSelectable={() => false}
            autoHeight
            pageSize={5}
            rowsPerPageOptions={[5]}
            columns={[
              { field: "owner", headerName: pack.colHeaders.owner, flex: 1 },
              { field: "animal", headerName: pack.colHeaders.animal, flex: 1 },
              {
                field: "date",
                headerName: pack.colHeaders.date,
                flex: 1,
                valueFormatter(params) {
                  return getDate(new Date(params.value));
                },
                sortComparator(v1, v2) {
                  const date1 = new Date(v1 as string).getTime();
                  const date2 = new Date(v2 as string).getTime();
                  if (date1 === date2) return 0;
                  return date1 - date2 > 0 ? 1 : -1;
                },
              },
              {
                field: "jobId",
                headerName: pack.colHeaders.view,
                sortable: false,
                filterable: false,
                renderCell: (params) => {
                  return (
                    <Button
                      href={`/job/${params.value}`}
                      variant="text"
                      size="small"
                    >
                      {pack.viewButton}
                    </Button>
                  );
                },
              },
            ]}
            rows={jobs
              .filter((job) => job.status === "completed")
              .map((job, i) => JobToDataRow(job, i))
              .filter((job): job is Row => job !== null)}
          />
        </div>
      </div>
      <span className="fixed bottom-12 right-5 flex flex-col items-end gap-3 print:invisible">
        <Tooltip title={pack.homeButton}>
          <Fab color="primary" size="large" component={Link} to="../">
            <Home fontSize="large" sx={{ color: "white" }} />
          </Fab>
        </Tooltip>
      </span>
      <Footer />
    </div>
  );
}
export default HistoryPage;
