// All shape types

type LineShape = {
  type: "line";
  point1: number;
  point2: number;
  dashed?: boolean;
};

type ShapeAttrs = {
  color: string;
};

type Shape = LineShape & ShapeAttrs;

// All constraint types

type PerpendicularConstraint = {
  type: "perpendicular";
  mainLine: number;
  fixedLine: number;
};

type Constraint = PerpendicularConstraint;

export type PointEditorSchema = {
  pntCount: number;
  shapes: Array<Shape>;
  constraints: Array<Constraint>;
};

// Some color constants.
const orange = "#eb841a";
const green = "#17ef18";
const blue = "#2e2ef8";

export const VHSVLASSchema: PointEditorSchema = {
  pntCount: 7,
  shapes: [
    { type: "line", point1: 0, point2: 1, color: green }, // spine
    { type: "line", point1: 2, point2: 3, color: orange }, // main axis
    { type: "line", point1: 4, point2: 5, color: orange, dashed: true }, // secondary axis
    { type: "line", point1: 2, point2: 6, color: blue }, // vlas line
  ],
  constraints: [{ type: "perpendicular", mainLine: 1, fixedLine: 2 }],
};
