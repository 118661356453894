// import { TextField } from "@mui/material";
import { ArrowForward, LockOutlined, MailOutline } from "@mui/icons-material";
import { Button, InputAdornment, styled } from "@mui/material";
import { Field, Formik } from "formik";
import { TextField } from "formik-mui";
import { useState } from "react";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useAuthState } from "../../helpers/AuthProvider";
import Footer from "../../helpers/Footer";
import { createDemoUser } from "../../logic/APIInterface";
import { useNavigate } from "react-router";

const GradientButton = styled(Button)({
  borderRadius: "25px",
  width: "120px",
  background:
    "linear-gradient(120deg, hsl(215deg 100% 67%) 10%, hsl(210deg 76% 37%) 100%)",
});

function LoginForm() {
  const [page, setPage] = useState<"login" | "signup">("login");

  const { loading, user, login } = useAuthState();
  const nav = useNavigate();

  return (
    <div className="w-screen min-h-screen flex flex-col justify-center items-center relative">
      <div className="w-fit bg-white rounded-md pb-20 pt-8 px-8 relative">
        <img
          src="assets/MetronMindLogo.png"
          alt={"Logo"}
          className="mx-auto w-64 mb-8"
        />
        {page === "login" && (
          <>
            <h1 className="text-2xl font-bold">Login</h1>
            <p className="text-gray-600 mb-12">Please sign in to continue</p>
            <Formik
              initialValues={{ email: "", password: "" }}
              validationSchema={Yup.object({
                email: Yup.string()
                  .required("Required.")
                  .email("Must be a valid email."),
                password: Yup.string().required("Required."),
              })}
              onSubmit={(values, { setSubmitting }) => {
                login(values.email, values.password).then((result) => {
                  setSubmitting(false);
                  if (!result) {
                    toast.error("Incorrect email or password.");
                  }
                });
              }}
            >
              {(formik) => (
                <div className="flex flex-col gap-8">
                  <Field
                    component={TextField}
                    label="Email"
                    name="email"
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <MailOutline fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <div className="relative w-fit h-fit">
                    <Field
                      component={TextField}
                      label="Password"
                      type="password"
                      name="password"
                      size="small"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockOutlined fontSize="small" />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <a
                      className="absolute top-1/2 transform -translate-y-1/2 right-2 text-xs font-bold text-brand-40 hover:bg-brand-10 hover:bg-opacity-40 p-1 rounded-md"
                      href="https://epona-billing-platform.com/Identity/Account/ForgotPassword"
                      target={"_blank"}
                      rel={"noreferrer"}
                    >
                      Forgot
                    </a>
                  </div>
                  <div className="flex flex-row justify-end">
                    <GradientButton
                      variant="contained"
                      onClick={formik.submitForm}
                      endIcon={<ArrowForward />}
                      disabled={formik.isSubmitting}
                    >
                      Login
                    </GradientButton>
                  </div>
                </div>
              )}
            </Formik>
            <span className="absolute bottom-4 left-0 w-full text-center text-sm text-gray-600 font-semibold tracking-wide">
              Don't have an account?{" "}
              <button
                className="inline-bloc font-bold text-brand-40"
                onClick={() => {
                  setPage("signup");
                }}
              >
                Sign up
              </button>
            </span>
          </>
        )}
        {page === "signup" && (
          <>
            <h1 className="text-2xl font-bold mb-12">Create Account</h1>
            <Formik
              initialValues={{ email: "", password: "", password2: "" }}
              validationSchema={Yup.object({
                email: Yup.string()
                  .required("Required.")
                  .email("Must be a valid email."),
                password: Yup.string()
                  .required("Required.")
                  .min(6, "Must be at least 6 characters long.")
                  .matches(
                    /[A-Z]/,
                    "Must contain at least one uppercase letter."
                  )
                  .matches(
                    /[a-z]/,
                    "Must have at least one lowercase letter."
                  )
                  .matches(
                    /[0-9]/,
                    "Must have at least one digit."
                  )
                  .matches(
                    /[^A-Za-z0-9]/,
                    "Must have a special character."
                  ),
                password2: Yup.string()
                  .required("Required.")
                  .oneOf([Yup.ref("password"), null], "Passwords must match."),
              })}
              onSubmit={(values, { setSubmitting }) => {
                createDemoUser(values.email, values.password)
                  .then((response) => {
                    nav("/confirmation");
                  })
                  .catch((err) => {
                    console.log(err.response);
                    if (err.response.status == 400) {
                      toast.error(err.response.data[0].message);
                    } else {
                      toast.error(
                        "Couldn't complete sign up request, try again later."
                      );
                    }
                  })
                  .finally(() => {
                    setSubmitting(false);
                  });
              }}
            >
              {(formik) => (
                <div className="flex flex-col gap-8">
                  <Field
                    component={TextField}
                    label="Email"
                    name="email"
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <MailOutline fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <div className="flex flex-col gap-4">
                    <Field
                      component={TextField}
                      label="Password"
                      type="password"
                      name="password"
                      size="small"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockOutlined fontSize="small" />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Field
                      component={TextField}
                      label="Confirm Password"
                      type="password"
                      name="password2"
                      size="small"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockOutlined fontSize="small" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div className="flex flex-row justify-end">
                    <GradientButton
                      variant="contained"
                      onClick={formik.submitForm}
                      endIcon={<ArrowForward />}
                      disabled={formik.isSubmitting}
                    >
                      Sign Up
                    </GradientButton>
                  </div>
                </div>
              )}
            </Formik>
            <span className="absolute bottom-4 left-0 w-full text-center text-sm text-gray-600 font-semibold tracking-wide">
              Already have an account?{" "}
              <button
                className="inline-bloc font-bold text-brand-40"
                onClick={() => {
                  setPage("login");
                }}
              >
                Sign In
              </button>
            </span>
          </>
        )}
      </div>
      <Footer />
    </div>
  );
}
export default LoginForm;
