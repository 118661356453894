import { Fragment, useEffect, useState } from "react";
import { Circle, Group, Image, Layer, Line, Stage, Text } from "react-konva";
import useImage from "use-image";
import {
  genLineFromConstants,
  getLineConstants,
  getNearestPointOnLine,
  lineConstantsForPerpendicularLine,
  Point,
  pointFallsOnLine,
  pointOfIntersection,
} from "./PointEditorMath";
import { PointEditorSchema } from "./PointEditorSchema";

type DraggablePoint = { x: number; y: number; isDragging: boolean };

type Props = {
  imageUrl: string;
  renderKey?: React.Key;
  className?: string;
  width: number;
  height: number;
  points: Array<Point>;
  schema: PointEditorSchema;
  onChange?: (points: Array<Point>) => void;
};
function PointEditor({
  imageUrl,
  className,
  renderKey,
  width,
  height,
  points,
  schema,
  onChange,
}: Props) {
  const [image] = useImage(imageUrl);
  const [imgSize, setImgSize] = useState<[number, number]>([width, height]);
  const [scale, setScale] = useState(1);
  const [cursor, setCursor] = useState("default");
  const [adjustedPoints, setAdjustedPoints] = useState<Array<DraggablePoint>>(
    []
  );
  useEffect(() => {
    if (image) {
      const imgW = image.width;
      const imgH = image.height;

      const wScale = width / imgW;
      const hScale = height / imgH;
      const minScale = Math.min(wScale, hScale);
      setScale(minScale);
      setImgSize([imgW * minScale, imgH * minScale]);
      const newPoints = points.map((pair) => ({
        x: pair.x * minScale,
        y: pair.y * minScale,
        isDragging: false,
      }));
      setAdjustedPoints(newPoints);
    }
  }, [image, width, height, points, renderKey]);

  useEffect(() => {
    if (onChange && adjustedPoints.length === schema.pntCount) {
      onChange(
        adjustedPoints.map((adjustedPoint) => ({
          x: Math.round(adjustedPoint.x / scale),
          y: Math.round(adjustedPoint.y / scale),
        }))
      );
    }
  }, [adjustedPoints]);

  function pointReducer(
    index: number,
    x: number,
    y: number,
    isDragging: boolean
  ) {
    adjustedPoints[index] = {
      x,
      y,
      isDragging,
    };
    schema.constraints.forEach((constraint) => {
      if (constraint.type === "perpendicular") {
        const shape1 = schema.shapes[constraint.mainLine];
        const shape2 = schema.shapes[constraint.fixedLine];
        const mainLine = {
          point1: adjustedPoints[shape1.point1],
          point2: adjustedPoints[shape1.point2],
        };
        const fixedLine = {
          point1: adjustedPoints[shape2.point1],
          point2: adjustedPoints[shape2.point2],
        };
        const allPointsIndexes = [
          shape1.point1,
          shape1.point2,
          shape2.point1,
          shape2.point2,
        ];
        const allPoints = [
          mainLine.point1,
          mainLine.point2,
          fixedLine.point1,
          fixedLine.point2,
        ];
        const whichPointIsThis = allPointsIndexes.findIndex(
          (pointIndex) => pointIndex === index
        );

        if (whichPointIsThis !== -1) {
          const point = allPoints[whichPointIsThis];
          if (whichPointIsThis >= 2) {
            // this point is on the fixed line
            const otherPointIndex = whichPointIsThis === 2 ? 3 : 2; //Hack to get the other point, since we know it is the other fixed point.
            const otherPoint = allPoints[otherPointIndex];
            const nearPointOnMainAxis = getNearestPointOnLine(point, mainLine);
            const whereOtherPointShouldBe = getNearestPointOnLine(otherPoint, {
              point1: point,
              point2: nearPointOnMainAxis,
            });
            adjustedPoints[allPointsIndexes[otherPointIndex]] = {
              x: whereOtherPointShouldBe.x,
              y: whereOtherPointShouldBe.y,
              isDragging: false,
            };
          } else {
            // This point is on the main axis, so we need to adjust the fixed points.

            const intersection = pointOfIntersection(mainLine, fixedLine);

            if (
              pointFallsOnLine(intersection, mainLine) &&
              pointFallsOnLine(intersection, fixedLine)
            ) {
              const fixedPoint1Index = allPointsIndexes[2];
              const fixedPoint1 = allPoints[2];
              const fixedPoint2Index = allPointsIndexes[3];
              const fixedPoint2 = allPoints[3];

              const perpLine = genLineFromConstants(
                lineConstantsForPerpendicularLine(intersection, mainLine)
              );

              adjustedPoints[fixedPoint1Index] = {
                ...getNearestPointOnLine(fixedPoint1, perpLine),
                isDragging: false,
              };
              adjustedPoints[fixedPoint2Index] = {
                ...getNearestPointOnLine(fixedPoint2, perpLine),
                isDragging: false,
              };
            }
          }
        }
      }
    });

    setAdjustedPoints([...adjustedPoints]);
    return { x, y };
  }

  return (
    <div className={className || ""}>
      <Stage
        key={renderKey || "stage"}
        width={imgSize[0]}
        height={imgSize[1]}
        style={{ cursor }}
      >
        <Layer>
          <Image image={image} width={imgSize[0]} height={imgSize[1]} />
          {adjustedPoints.length === schema.pntCount &&
            schema.shapes.map((shape, index) => {
              if (shape.type === "line") {
                const point1 = adjustedPoints[shape.point1];
                const point2 = adjustedPoints[shape.point2];
                return (
                  <Line
                    key={`LINE${index}`}
                    points={[point1.x, point1.y, point2.x, point2.y]}
                    stroke={shape.color}
                    strokeWidth={2}
                    dash={[5, 5]}
                    dashEnabled={shape.dashed || false}
                  />
                );
              }
              return <></>;
            })}
          {adjustedPoints.map((pair, index) => {
            return (
              <Fragment key={`CIRCLE${index}`}>
                <Group
                  {...pair}
                  draggable
                  onDragEnd={(e) => {
                    pointReducer(index, e.target.x(), e.target.y(), false);
                  }}
                  onDragMove={(e) => {
                    const x = e.target.x();
                    const y = e.target.y();
                    const { x: newX, y: newY } = pointReducer(
                      index,
                      x,
                      y,
                      true
                    );
                    if (newX !== x) e.target.x(newX);
                    if (newY !== y) e.target.y(newY);
                  }}
                  onMouseEnter={(e) => {
                    setCursor("pointer");
                  }}
                  onMouseLeave={(e) => {
                    setCursor("default");
                  }}
                >
                  <Circle fill={"#ff0000"} radius={3} />
                  <Text
                    text={(index + 1).toString()}
                    x={6}
                    y={0}
                    fill="#ffffff"
                  />
                </Group>
              </Fragment>
            );
          })}
        </Layer>
      </Stage>
    </div>
  );
}
export default PointEditor;
