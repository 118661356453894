import { Button } from "@mui/material";
import { useLanguage } from "../../helpers/LanguageProvider";

type Props = {};
function UpgradeAccount({}: Props) {
  const pack = useLanguage().modals.upgradeAccount;
  return (
    <div className="space-y-3 flex flex-col justify-center">
      <p className="text-center text-lg font-semibold">{pack.header}</p>
      <p>{pack.blob}</p>
      <Button
        variant="contained"
        onClick={() => {
          window.location.href =
            "https://epona-billing-platform.com/Identity/Account/Register-Bl";
        }}
      >
        {pack.button}
      </Button>
    </div>
  );
}
export default UpgradeAccount;
