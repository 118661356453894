export type Point = {
  x: number;
  y: number;
};
export type Line = {
  point1: Point;
  point2: Point;
};

/**
 * Return the point of intersection using [this formula.](https://www.geeksforgeeks.org/point-of-intersection-of-two-lines-formula/#:~:text=Point%20of%20intersection%20is%20the,of%20intersection%20is%20a%20line.)
 */
export function pointOfIntersection(line1: Line, line2: Line) {
  const { a: a1, b: b1, c: c1 } = getLineConstants(line1);
  const { a: a2, b: b2, c: c2 } = getLineConstants(line2);
  const x = (b1 * c2 - b2 * c1) / (a1 * b2 - a2 * b1);
  const y = (c1 * a2 - c2 * a1) / (a1 * b2 - a2 * b1);
  return { x, y } as Point;
}

/**
 * Given a line, return the a,b,c constants such that ax+by+c=0 represents the line perfectly.
 */
export function getLineConstants(line: Line) {
  const p1 = line.point1;
  const p2 = line.point2;
  const rise = p2.y - p1.y;
  const run = p2.x - p1.x;
  if (run === 0) return { a: 0, b: 1, c: p1.y };
  return { a: -rise, b: run, c: rise * p1.x - run * p1.y };
}

export function pointFallsOnLine(point: Point, line: Line) {
  const { x, y } = point;
  const { point1: p1, point2: p2 } = line;

  const tx = (x - p1.x) / (p2.x - p1.x);
  const ty = (y - p1.y) / (p2.y - p1.y);
  return Math.abs(tx - ty) < 1 && tx >= 0 && tx <= 1;
}

/**
 * Get the nearest point on a line from [here](https://stackoverflow.com/questions/3120357/get-closest-point-to-a-line)
 */
export function getNearestPointOnLine(point: Point, line: Line) {
  const A = line.point1;
  const B = line.point2;
  const P = point;

  const a_to_p = [P.x - A.x, P.y - A.y];
  const a_to_b = [B.x - A.x, B.y - A.y];

  const atb2 = Math.pow(a_to_b[0], 2) + Math.pow(a_to_b[1], 2);

  const atp_dot_atb = a_to_p[0] * a_to_b[0] + a_to_p[1] * a_to_b[1];

  const t = atp_dot_atb / atb2;

  return { x: A.x + a_to_b[0] * t, y: A.y + a_to_b[1] * t } as Point;
}

export function lineConstantsForPerpendicularLine(point: Point, line: Line) {
  const { a, b } = getLineConstants(line);

  return { a: -b, b: a, c: b * point.x - a * point.y };
}

export function genLineFromConstants(constants: {
  a: number;
  b: number;
  c: number;
}) {
  const { a, b, c } = constants;
  const y = (x: number) => (-a / b) * x - c / b;
  return { point1: { x: 0, y: y(0) }, point2: { x: 10, y: y(10) } } as Line;
}

export function pointsEqual(p1: Point, p2: Point): boolean {
  return Math.abs(p1.x - p2.x) < 1 && Math.abs(p1.y - p2.y) < 1;
}
