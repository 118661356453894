import { Button, CircularProgress } from "@mui/material";
import { useState } from "react";
import CustomTextField from "../../helpers/CustomTextField";
import { toast } from "react-toastify";
import config from "../../config";
import { useLanguage } from "../../helpers/LanguageProvider";
import { useAuthState } from "../../helpers/AuthProvider";

type Props = {
  onSuccess: () => void;
};
function RedeemCoupon({ onSuccess }: Props) {
  const [coupon, setCoupon] = useState("");
  const [loading, setLoading] = useState(false);
  const { redeem } = useAuthState();
  const pack = useLanguage().modals.redeem;
  return (
    <div className="space-y-3 flex flex-col justify-center">
      <p className="text-center text-lg font-semibold">{pack.header}</p>
      <CustomTextField
        value={coupon}
        onChange={(e) => {
          setCoupon(e.currentTarget.value);
        }}
        size="small"
        label={pack.codeLabel}
      />
      <Button
        variant="contained"
        disabled={coupon.length === 0 || loading}
        onClick={(e) => {
          setLoading(true);
          redeem(coupon)
            .then((result) => {
              onSuccess();
            })
            .catch((err) => {
              toast.error(err);
            })
            .finally(() => {
              setLoading(false);
            });
        }}
      >
        {loading && <CircularProgress size={14} sx={{ mr: 1 }} />}
        {pack.redeemButton}
      </Button>
    </div>
  );
}
export default RedeemCoupon;
