import { useModalMaker } from "./ModalProvider";

type Props = { src: string; alt: string };
function EnlargableImage({ src, alt }: Props) {
  const { setModal } = useModalMaker();
  return (
    <>
      <div
        className="w-64 mx-auto h-fit hover:cursor-pointer"
        onClick={() => {
          setModal(
            <div className="bg-white box-border rounded-md px-10 pt-4">
              <img
                src={src}
                alt={alt}
                className="max-h-[90vh] object-contain"
              />
            </div>
          );
        }}
      >
        <img src={src} alt="VHS" className="w-full" />
      </div>
      <p className="text-center w-64 text-sm mx-auto print:hidden">
        Click image to enlarge
      </p>
    </>
  );
}
export default EnlargableImage;
