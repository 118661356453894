import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  Container,
  CssBaseline,
  Fab,
  IconButton,
  Stack,
  styled,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import {
  Download,
  FavoriteBorder,
  Home,
  Print,
  Send,
} from "@mui/icons-material";
import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getJob } from "../../logic/APIInterface";
import { CompletedJob, CompletedJobError, InProgressJob } from "../../types";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import { Auth } from "aws-amplify";
import ReportViewer, { getDate } from "./ReportViewer";
import { useModalMaker } from "../../helpers/ModalProvider";
import ShareScreen from "./ShareScreen";
import { Link } from "react-router-dom";
import printJS from "print-js";
import { useLanguage } from "../../helpers/LanguageProvider";
import { useAuthState } from "../../helpers/AuthProvider";
import Footer from "../../helpers/Footer";

const loadingGif = (
  <img
    src="/assets/MMLoading.gif"
    alt="Loading gif"
    style={{
      margin: "0 auto",
      maxWidth: 400,
    }}
  />
);

const StyledFab = styled(Fab)({
  "&": {
    backgroundColor: "#39b158",
  },
  "&:hover": {
    backgroundColor: "#2ea14c",
  },
});

function Jobs() {
  const params = useParams();
  const [loadingJob, setLoadingJob] = useState(true);
  const navigate = useNavigate();
  const [job, setJob] = useState<
    InProgressJob | CompletedJob | CompletedJobError | null
  >(null);
  const { setModal } = useModalMaker();
  const { token, user } = useAuthState();
  const pack = useLanguage().jobs;

  useEffect(() => {
    if (!params.jobId) return;
    let interval: NodeJS.Timer;
    getJob(params.jobId!, token || undefined)
      .then((job) => {
        setJob(job);
        if (job.status === "in_progress") {
          if (!interval)
            interval = setInterval(() => {
              if (!params.jobId) return;
              getJob(params.jobId, token || undefined).then((newJob) => {
                if (newJob.status !== job.status) {
                  setJob(newJob);
                  clearInterval(interval);
                }
              });
            }, 2000);
        }
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => {
        setLoadingJob(false);
      });
    return () => {
      clearInterval(interval);
    };
  }, []);

  const [fabs, setFabs] = useState<Array<JSX.Element>>([]);

  useEffect(() => {
    if (job && job.status === "completed") {
      setFabs([
        <Tooltip title={pack.buttons.downloadTip}>
          <span
            onClick={() => {
              if (!job) return;
              const completeJob = job as CompletedJob;
              const metadata = job.metadata as {
                animal: string;
                breed: string;
                owner: string;
                patientId: string;
                date: string;
              };
              saveAs(
                (job as CompletedJob).output.report || "",
                `${metadata.owner}.${metadata.animal}.${getDate(
                  new Date(metadata.date)
                )}.pdf`
              );
            }}
          >
            <Fab color="primary">
              <Download fontSize="large" sx={{ color: "white" }} />
            </Fab>
          </span>
        </Tooltip>,
        <Tooltip title={pack.buttons.shareTip}>
          <span
            onClick={() => {
              const completeJob = job as CompletedJob;
              setModal(<ShareScreen job={completeJob} />);
            }}
          >
            <Fab color="primary">
              <Send fontSize="large" sx={{ color: "white" }} />
            </Fab>
          </span>
        </Tooltip>,
        <Tooltip title={pack.buttons.printTip}>
          <span
            onClick={() => {
              const completeJob = job as CompletedJob;
              printJS({
                printable: completeJob.output.report || "",
                showModal: true,
              });
            }}
          >
            <Fab color="primary">
              <Print fontSize="large" sx={{ color: "white" }} />
            </Fab>
          </span>
        </Tooltip>,
        ...fabs,
      ]);
    }
  }, [job]);

  const isMyJob = (user && user.uid === job?.owner_id) || false;
  return (
    <div>
      <div className="w-[100%] min-h-screen flex flex-col justify-center items-center">
        {loadingJob ||
          (job?.status === "in_progress" && (
            <div className="w-fit space-y-6 bg-white p-4 rounded-md">
              {loadingGif}
              {job?.status === "in_progress" && (
                <Alert severity="info">{pack.loading}</Alert>
              )}
            </div>
          ))}
        {!loadingJob && !job && <h1>{pack.redirecting}</h1>}
        {job && job.status === "completed" && (
          <ReportViewer
            job={job as CompletedJob}
            myJob={isMyJob}
            addFab={(fab: JSX.Element) => {
              fabs.push(fab);
              setFabs([...fabs]);
            }}
          />
        )}
        {job && job.status === "error" && (
          <div className="bg-white w-fit p-2 rounded-md">
            <Alert severity="error">
              {(job as CompletedJobError).error.message}
            </Alert>
          </div>
        )}
        <span className="fixed bottom-12 right-5 flex flex-col items-end gap-3 print:invisible">
          <Tooltip title={pack.buttons.homeTip}>
            <Fab color="primary" size="large" component={Link} to="../../">
              <Home fontSize="large" sx={{ color: "white" }} />
            </Fab>
          </Tooltip>
          {job && job.status === "completed" ? (
            <>
              {fabs.map((Fab, index) => (
                <Fragment key={index}>{Fab}</Fragment>
              ))}
            </>
          ) : (
            <></>
          )}
        </span>
        <Footer position="static" />
      </div>
    </div>
  );
}
export default Jobs;
