import { useFormikContext } from "formik";
import CustomTextField from "../../helpers/CustomTextField";
import { useLanguage } from "../../helpers/LanguageProvider";
import { VHSFormValues } from "../../types";

type Props = {};
function PatientInfo({}: Props) {
  const formik = useFormikContext<VHSFormValues>();
  const pack = useLanguage().main.patientInfo;
  return (
    <div className="w-full h-64 bg-neutral-100 border border-neutral-300 rounded-sm flex flex-col items-center gap-y-1">
      <h4 className="text-center font-sans text-lg font-semibold my-1">
        {pack.header}
      </h4>
      <div className="box-border h-full flex flex-col justify-evenly ">
        <CustomTextField
          {...formik.getFieldProps("owner")}
          required
          label={pack.ownerNameLabel}
          size="small"
          sx={{ maxWidth: 300 }}
          error={Boolean(formik.errors.owner) && formik.touched.owner}
          helperText={
            Boolean(formik.errors.owner) && formik.touched.owner
              ? formik.errors.owner
              : null
          }
        />
        <CustomTextField
          {...formik.getFieldProps("animal")}
          label={pack.animalNameLabel}
          required
          size="small"
          sx={{ maxWidth: 300 }}
          error={Boolean(formik.errors.animal) && formik.touched.animal}
          helperText={
            Boolean(formik.errors.animal) && formik.touched.animal
              ? formik.errors.animal
              : null
          }
        />
        <CustomTextField
          {...formik.getFieldProps("breed")}
          label={pack.breedLabel}
          size="small"
          sx={{ maxWidth: 300 }}
        />
        <CustomTextField
          {...formik.getFieldProps("patientId")}
          label={pack.patientIdLabel}
          size="small"
          sx={{ maxWidth: 300 }}
        />
      </div>
    </div>
  );
}
export default PatientInfo;
