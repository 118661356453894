import { createContext, ReactNode, useContext, useState } from "react";

export type LanguagePack = {
  main: {
    header: string;
    redeemSucessToast: string;
    accountButton: {
      label: string;
      history: string;
      redeem: string;
      signOut: string;
    };
    usesIndicator: (uses: number, hasSub: boolean) => string;
    getMoreButton: string;
    imageUpload: {
      header: string;
      dragAndDrop: {
        imageUploaded: string;
        clearButton: string;
        main: string;
        or: string;
        browseButton: string;
        fileTypes: string;
        clearTip: string;
        toasts: {
          wrongFileType: string;
          wrongFileCount: string;
        };
      };
      dateTakenLabel: string;
    };
    patientInfo: {
      header: string;
      ownerNameLabel: string;
      animalNameLabel: string;
      breedLabel: string;
      patientIdLabel: string;
    };
    analyzeButton: string;
  };
  jobs: {
    loading: string;
    redirecting: string;
    buttons: {
      homeTip: string;
      downloadTip: string;
      shareTip: string;
      printTip: string;
    };
    report: {
      header: string;
      ownerLabel: string;
      animalLabel: string;
      dateLabel: string;
      clickToEnlargeText: string;
      noImage: string;
      pointEditor: {
        fabHint: string;
        save: string;
        title: string;
        reset: string;
      };
      results: {
        header: string;
        blob1: string;
      };
      analysis: {
        header: string;
        blob1: (vhs: number, vlas: number) => string;
        blobNormal: string;
        blobBorderline: string;
        blobEnlarged: string;
      };
      poweredBy: string;
    };
  };
  history: {
    header: string;
    colHeaders: {
      owner: string;
      animal: string;
      date: string;
      view: string;
    };
    viewButton: string;
    homeButton: string;
  };
  modals: {
    redeem: {
      header: string;
      codeLabel: string;
      redeemButton: string;
    };
    share: {
      header: string;
      byLink: {
        tabLabel: string;
        header: string;
        linkHelperText: string;
      };
      byEmail: {
        tabLabel: string;
        header: string;
        recipientLabel: string;
        subjectLabel: string;
        messageLabel: string;
        rendered: {
          header: string;
        };
        sendEmailButton: string;
        toastSuccess: string;
      };
    };
    upgradeAccount: {
      header: string;
      blob: string;
      button: string;
    };
  };
  formErrors: {
    required: string;
    fileTooLarge: string;
    fileWrongFormat: string;
    notAnEmail: string;
    max50: string;
  };
};

export const english: LanguagePack = {
  history: {
    header: "Report History",
    viewButton: "View",
    colHeaders: {
      owner: "Owner",
      animal: "Animal",
      date: "Date",
      view: "View Report",
    },
    homeButton: "Go Home",
  },
  formErrors: {
    required: "Required.",
    fileTooLarge: "File is too large.",
    fileWrongFormat: "Incorrect file format.",
    notAnEmail: "Not an email.",
    max50: "Too many characters (max 50).",
  },
  main: {
    header: "VHS/VLAS Calculator",
    redeemSucessToast: "Successfully redeemed coupon!",
    usesIndicator: (uses,hasSub) => {
      if (uses > 0) {
        return `You have <b>${uses}</b> free use${uses === 1 ? "" : "s"} left.`;
      } else {
        return "You have <b>NO</b> free uses left."+(hasSub ? " You will be charged for any future jobs." : "");
      }
    },
    getMoreButton: "Get more",
    accountButton: {
      label: "Account",
      history: "History",
      redeem: "Redeem",
      signOut: "Sign out",
    },
    analyzeButton: "Analyze",
    imageUpload: {
      header: "Image Upload",
      dateTakenLabel: "Date Taken",
      dragAndDrop: {
        imageUploaded: "Image uploaded successfully.",
        clearButton: "Clear File",
        main: "Drag & Drop file here",
        or: "or",
        browseButton: "Browse File",
        fileTypes: "*.jpg, *.tif, *.png, *.bmp, *.dcm only",
        clearTip: "Clear Image",
        toasts: {
          wrongFileCount: "Must upload 1 file.",
          wrongFileType: "Invalid file type.",
        },
      },
    },
    patientInfo: {
      header: "Patient Info",
      ownerNameLabel: "Owner Name",
      animalNameLabel: "Animal Name",
      breedLabel: "Breed",
      patientIdLabel: "Patient ID",
    },
  },
  modals: {
    redeem: {
      header: "Redeem Coupon",
      codeLabel: "Code",
      redeemButton: "Redeem",
    },
    share: {
      header: "Share Report",
      byLink: {
        tabLabel: "By link",
        header: "Get Link",
        linkHelperText:
          "This link can be shared with anyone, and they will be brought to this same page.",
      },
      byEmail: {
        tabLabel: "By email",
        header: "Customize Email",
        recipientLabel: "Recipient Email",
        subjectLabel: "Subject",
        messageLabel: "Message",
        sendEmailButton: "Send Email",
        rendered: {
          header: "Rendered Email",
        },
        toastSuccess: "Sent email successfully.",
      },
    },
    upgradeAccount: {
      header: "Upgrade Account",
      blob: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus feugiat, velit a malesuada commodo.",
      button: "Upgrade",
    },
  },
  jobs: {
    redirecting: "Redirecting...",
    loading: "MetronMind is processing your image.",
    buttons: {
      homeTip: "Submit Another Job",
      downloadTip: "Download as PDF",
      shareTip: "Share Report",
      printTip: "Print Report",
    },
    report: {
      header: "VHS/VLAS Report",
      noImage: "Missing image.",
      pointEditor: {
        fabHint: "Adjust markup.",
        reset: "Reset Points",
        save: "Save",
        title: "Adjust Markup",
      },
      ownerLabel: "Owner: ",
      animalLabel: "Animal: ",
      dateLabel: "Date: ",
      results: {
        header: "Results",
        blob1:
          "The Vertebral Heart Score (VHS) and Vertebral Left Atrium Score (VLAS) are two measurements made to help your veterinarian assess whether your pet's heart is in good health or not. These scores, along with the rest of your pet's check-up by your veterinarian, can help to monitor your pet's well-being.",
      },
      analysis: {
        header: "Analysis",
        blob1: (vhs, vlas) =>
          `The VHS measurement for this dog is <b>${vhs.toFixed(
            1
          )}</b> and the VLAS measurement is <b>${vlas.toFixed(1)}</b>.`,
        blobNormal:
          "The heart does not appear to be enlarged, and is in the normal size range.",
        blobBorderline:
          "The measurements show a 'borderline' situation. An echocardiagram is recommended to make a final determination of the health of the heart.",
        blobEnlarged: `According to the updated ACVIM consensus guidelines for the diagnosis and treatment of myxomatous mitral valve disease in dogs, your dog meets the requirements to be diagnosed with having Stage B2 MMVD.

According to the same guidelines, the recommended treatment for Stage B2 MMVD is Vetmedin-CA1. 

Vetmedin-CA1 can delay the onset of congestive heart failure and provide your dog, on average, prolonged symptom-free life by 15 months.

The recommended dose of Vetmedin-CA1 is a total daily dose of 0.23 mg/lb (0.5 mg/kg) body weight, using a suitable combination of whole or half tablets. The total daily dose should be divided into 2 portions that are not necessarily equal, and the portions should be administered approximately 12 hours apart.`,
      },
      clickToEnlargeText: "Click image to enlarge",
      poweredBy: "Analysis powered by ",
    },
  },
};

type LanguageContext = {
  pack: LanguagePack;
  setLanguage: (language: "en") => void;
};

const Context = createContext({} as LanguageContext);

export function useLanguage() {
  return useContext(Context).pack;
}

export default function LanguageProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [pack, setPack] = useState(english);
  return (
    <Context.Provider
      value={{
        pack,
        setLanguage: (lang) => {
          if (lang === "en") setPack(english);
        },
      }}
    >
      {children}
    </Context.Provider>
  );
}
