import { TextField } from "@mui/material";
import { styled } from "@mui/system";

export default styled(TextField)({
  "& .MuiOutlinedInput-root": {
    background: "white",
  },
  "& .MuiFormHelperText-root": {
    color: "#010101",
    marginTop: 0,
  },
  "& .MuiFormHelperText-root.Mui-error": {
    color: "#d32f2f",
  },
});
