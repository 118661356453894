import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useAuthState } from "../../../helpers/AuthProvider";
import { useLanguage } from "../../../helpers/LanguageProvider";
import { useWindowSize } from "../../../helpers/useWindowSize";
import { resubmitPoints } from "../../../logic/APIInterface";
import PointEditor from "../PointEditor";
import { Point, pointsEqual } from "../PointEditorMath";
import { PointEditorSchema } from "../PointEditorSchema";

type Props = {
  imageUrl: string;
  points: Array<Point>;
  schema: PointEditorSchema;
  jobId: string;
};
function ReportEditor({ imageUrl, points: originalPoints, schema, jobId }: Props) {
  const size = useWindowSize();
  const [points, setPoints] = useState(originalPoints);
  const [pntEditorKey, setPntEditorKey] = useState(0);
  const [pointsChanged, setPointsChanged] = useState(false);
  const width = size.width || 500;
  const height = (size.height || 500) - 150;
  const {token} = useAuthState();
  const navigate = useNavigate();

  const pack = useLanguage().jobs.report.pointEditor;

  useEffect(() => {
    if (!pointsChanged) {
      const haveAnyPointsChanged = originalPoints.reduce(
        (acc, oPoint, index) => {
          return Boolean(acc || !pointsEqual(oPoint, points[index]));
        },
        false
      );
      setPointsChanged(haveAnyPointsChanged);
    }
  }, [points]);

  return (
    <div className="flex flex-col justify-center">
      <h1 className="text-xl font-bold mx-auto">{pack.title}</h1>
      <div className="flex flex-col mt-2 gap-2 mx-4">
        <PointEditor
          renderKey={pntEditorKey}
          className="border-2"
          points={originalPoints}
          width={width}
          height={height}
          imageUrl={imageUrl}
          schema={schema}
          onChange={(newPoints) => {
            setPoints(newPoints);
          }}
        />
        <div className="button-toolbar w-full flex flex-row justify-center">
          <Button variant="contained" disabled={!pointsChanged} onClick={()=>{
            resubmitPoints(token || "",jobId,points.flatMap((point)=>[point.x,point.y])).then(res=>{
              console.log(res.data);
              navigate(`/job/${res.data}`);
              window.location.reload();
            }).catch(err=>{
              console.log(err.response);
            });
          }}>
            {pack.save}
          </Button>
          <Button
            variant="text"
            onClick={() => {
              setPoints(originalPoints);
              setPointsChanged(false);
              setPntEditorKey(pntEditorKey + 1);
            }}
            disabled={!pointsChanged}
          >
            {pack.reset}
          </Button>
        </div>
      </div>
    </div>
  );
}
export default ReportEditor;
