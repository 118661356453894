import { TabItem } from "@aws-amplify/ui-react";
import { Button, Tab, Tabs, TextField } from "@mui/material";
import { Formik } from "formik";
import { useState } from "react";
import { useModalMaker } from "../../helpers/ModalProvider";
import { CompletedJob } from "../../types";
import * as Yup from "yup";
import { sendEmail } from "../../logic/APIInterface";
import { toast } from "react-toastify";
import { useLanguage } from "../../helpers/LanguageProvider";
import { useAuthState } from "../../helpers/AuthProvider";
import { TabPanel } from "./TabPanel";

type Props = { job: CompletedJob };
function ShareScreen({ job }: Props) {
  const [tab, setTab] = useState(0);

  const { token } = useAuthState();

  const { setGracePeriod } = useModalMaker();
  const pack = useLanguage().modals.share;
  const formPack = useLanguage().formErrors;
  return (
    <div className="overflow-auto max-h-[90vh]">
      <div className="space-y-3 flex flex-col justify-center px-3">
        <p className="text-center text-lg font-semibold">{pack.header}</p>
        <Tabs
          value={tab}
          onChange={(event, i) => {
            setGracePeriod(200);
            setTab(parseInt(i.toString()));
          }}
        >
          <Tab label={pack.byLink.tabLabel} />
          <Tab label={pack.byEmail.tabLabel} />
        </Tabs>
        <TabPanel value={tab} index={0}>
          <div className="space-y-2">
            <p className="text-md font-semibold mb-2">{pack.byLink.header}</p>
            <span
              className="text-green-800 rounded-md bg-green-100 p-2"
              onClick={(e) => {
                const range = document.createRange();
                range.selectNodeContents(e.currentTarget);
                const sel = window.getSelection();
                if (sel) {
                  sel.removeAllRanges();
                  sel.addRange(range);
                }
              }}
            >
              {window.location.href}
            </span>
            <p className="text-xs ml-2 text-gray-700">
              {pack.byLink.linkHelperText}
            </p>
          </div>
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <div className="space-y-2 flex flex-col">
            <p className="text-md font-semibold mb-2">{pack.byEmail.header}</p>
            <Formik
              initialValues={{
                email: "",
                subject: "",
                message: "",
              }}
              validationSchema={Yup.object({
                email: Yup.string()
                  .email(formPack.notAnEmail)
                  .required(formPack.required),
                subject: Yup.string()
                  .required(formPack.required)
                  .max(50, formPack.max50),
                message: Yup.string(),
              })}
              onSubmit={(
                values,
                { setSubmitting, setFieldValue, setFieldTouched }
              ) => {
                setSubmitting(true);
                sendEmail(job.id, values, token || undefined)
                  .then((res) => {
                    toast.success(pack.byEmail.toastSuccess);
                  })
                  .catch((err) => {
                    toast.error(err);
                  })
                  .finally(() => {
                    setFieldValue("email", "");
                    setFieldTouched("email", false);
                    setSubmitting(false);
                  });
              }}
            >
              {(formik) => (
                <>
                  <TextField
                    label={pack.byEmail.recipientLabel}
                    size="small"
                    sx={{ maxWidth: 300 }}
                    {...formik.getFieldProps("email")}
                    error={Boolean(formik.errors.email) && formik.touched.email}
                    helperText={
                      Boolean(formik.errors.email) && formik.touched.email
                        ? formik.errors.email
                        : null
                    }
                  />
                  <TextField
                    label={pack.byEmail.subjectLabel}
                    size="small"
                    sx={{ maxWidth: 300 }}
                    {...formik.getFieldProps("subject")}
                    error={
                      Boolean(formik.errors.subject) && formik.touched.subject
                    }
                    helperText={
                      Boolean(formik.errors.subject) && formik.touched.subject
                        ? formik.errors.subject
                        : null
                    }
                  />
                  <TextField
                    label={pack.byEmail.messageLabel}
                    size="small"
                    multiline
                    {...formik.getFieldProps("message")}
                    error={
                      Boolean(formik.errors.message) && formik.touched.message
                    }
                    helperText={
                      Boolean(formik.errors.message) && formik.touched.message
                        ? formik.errors.message
                        : null
                    }
                  />
                  <div className="m-2 bg-gray-50 rounded-md border border-slate-300 p-1 w-screen md:max-w-md">
                    <p className="text-center font-semibold">
                      {pack.byEmail.rendered.header}
                    </p>
                    <div className="p-1">
                      <p className="font-semibold text-sm">
                        FROM: MetronMind
                        &lt;metron@epona-intellect-engine.com&gt;
                      </p>
                      <p className="font-semibold text-sm">
                        TO: {formik.values.email}
                      </p>
                      <p className="font-semibold text-sm">
                        SUBJ: {formik.values.subject}
                      </p>
                      <p className="text-sm">
                        You are being sent a report by a MetronMind user.
                        <br />
                        <br />
                        {formik.values.message && (
                          <>
                            The user sending you the report has attached the
                            following message:
                            <br />"{formik.values.message}"
                            <br />
                            <br />
                          </>
                        )}
                        To view the report, click{" "}
                        <a
                          href={window.location.href}
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                          className="text-blue-700 hover:text-blue-800"
                        >
                          here
                        </a>
                        .
                      </p>
                    </div>
                  </div>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={
                      formik.isSubmitting || !(formik.isValid && formik.dirty)
                    }
                    onClick={formik.submitForm}
                  >
                    {pack.byEmail.sendEmailButton}
                  </Button>
                </>
              )}
            </Formik>
          </div>
        </TabPanel>
      </div>
    </div>
  );
}
export default ShareScreen;
