import { Button, CircularProgress, Tooltip } from "@mui/material";
import {
  CheckCircle,
  Close,
  CloudUploadOutlined,
  Error,
  ErrorOutline,
} from "@mui/icons-material";
import { useFormikContext } from "formik";
import { VHSFormValues } from "../../types";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import config from "../../config";
import { useLanguage } from "../../helpers/LanguageProvider";

type Props = {};
function ImageDragAndDrop({}: Props) {
  const formik = useFormikContext<VHSFormValues>();
  const [image, setImage] = useState<string>("");
  const [isDraggedOver, setIsDraggedOver] = useState(0);
  const boxRef = useRef<HTMLDivElement>(null);
  const pack = useLanguage().main.imageUpload.dragAndDrop;

  useEffect(() => {
    if (formik.values.file) {
      setTimeout(() => {
        if (formik.values.file)
          setImage(URL.createObjectURL(formik.values.file));
      }, 300);
    } else {
      setImage("");
    }
  }, [formik.values.file]);

  if (formik.values.file) {
    if (image) {
      if (formik.errors.file) {
        return (
          <div
            className={
              "h-44 mx-4 lg:mx-12 rounded-md relative border-2 border-red-200 bg-red-50 box-border flex flex-col justify-center items-center transition "
            }
          >
            <span className="text-center text-red-600">
              <ErrorOutline sx={{ fontSize: 90 }} />
            </span>
            <p>{formik.errors.file}</p>
            <Button
              variant="text"
              color="primary"
              onClick={() => {
                setImage("");
                formik.setFieldValue("file", null);
              }}
            >
              {pack.clearButton}
            </Button>
          </div>
        );
      }
      return (
        <div
          className={
            "h-44 mx-4 lg:mx-12 rounded-md relative border-2 border-green-200 bg-green-50 box-border flex flex-col justify-center items-center transition "
          }
        >
          <span className="text-center text-green-600">
            <CheckCircle sx={{ fontSize: 85 }} />
          </span>
          <p className="text-center">{pack.imageUploaded}</p>
          <p className="italic max-w-full text-center">
            {formik.values.file.name}
          </p>
          <Button
            variant="text"
            color="primary"
            onClick={() => {
              setImage("");
              formik.setFieldValue("file", null);
            }}
          >
            {pack.clearButton}
          </Button>
        </div>
      );
      /* the text for preview images.
      return (
          <div className="group h-44 w-fit max-w-full mx-auto relative border-1 border-brand-90 bg-transparent box-border flex flex-col place-content-center items-center rounded-sm">
            <img
              src={image}
              onError={(e) => {
                setImageCantBeDisplayed(true);
              }}
              alt="VHS"
              className="w-full h-full object-contain"
            />
            <div className="bg-transparent group-hover:bg-black group-hover:bg-opacity-40 invisible group-hover:visible transition duration-500 ease-in-out w-full h-full absolute box-border backdrop-blur-[1px]">
              <span
                className="text-white absolute top-1 right-1 bg-gray-900 bg-opacity-0 hover:bg-opacity-50 transition rounded-full cursor-pointer p-1"
                onClick={(e) => {
                  setImage("");
                  formik.setFieldValue("file", null);
                }}
              >
                <Tooltip
                  title={pack.clearTip}
                  className="invisible group-hover:visible text-opacity-0 text-black group-hover:text-opacity-100 group-hover:text-white"
                >
                  <Close sx={{ fontSize: 30 }} />
                </Tooltip>
              </span>
            </div>
          </div>
        ); */
    } else {
      return (
        <div className="h-44 mx-4 lg:mx-12 rounded-md border-2 border-dashed border-neutral-300 bg-neutral-50 box-border flex flex-col place-content-center items-center">
          <CircularProgress />
        </div>
      );
    }
  }

  const draggedClasses: string = "bg-brand-10 border-brand-60";
  return (
    <div
      className={
        "h-44 mx-4 lg:mx-12 rounded-md border-2 relative border-dashed border-neutral-300 bg-white box-border flex flex-col place-content-center items-center transition " +
        (isDraggedOver > 0 ? draggedClasses : "")
      }
      ref={boxRef}
      onDragEnter={() => {
        setIsDraggedOver((val) => val + 1);
      }}
      onDragLeave={(e) => {
        setIsDraggedOver((val) => val - 1);
      }}
      onDragOver={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      onDrop={(e) => {
        setIsDraggedOver(0);
        e.preventDefault();
        if (e.dataTransfer.files.length === 1) {
          const file = e.dataTransfer.files[0];
          if (
            config.accepts.split(",").includes(file.type) ||
            file.name.endsWith(".dcm")
          ) {
            formik.setFieldValue("file", file);
          } else {
            toast.error(pack.toasts.wrongFileType);
          }
        } else {
          toast.error(pack.toasts.wrongFileCount);
        }
      }}
    >
      <span className="text-center">
        <CloudUploadOutlined fontSize="large" />
      </span>
      <p className="text-center font-sans text-lg">{pack.main}</p>
      <p className="text-center font-sans pb-1 italic">{pack.or}</p>
      <label htmlFor="file" className="w-fit">
        <Button
          className="w-36 mx-auto font-semibold h-8"
          variant="outlined"
          component="label"
        >
          {pack.browseButton}
          <input
            name="file"
            type="file"
            id="file"
            hidden
            accept={config.accepts}
            onChange={(event) => {
              const files = event.currentTarget.files;
              formik.setFieldTouched("file");
              formik.setFieldValue("file", files ? files[0] : null);
            }}
          />
        </Button>
      </label>
      <p className="text-center font-semibold text-sm pt-1 italic">
        {pack.fileTypes}
      </p>
    </div>
  );
}
export default ImageDragAndDrop;
