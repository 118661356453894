import { useAuthenticator } from "@aws-amplify/ui-react";
import { Close } from "@mui/icons-material";
import { API } from "aws-amplify";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

type ModalState = {
  setModal: (element: JSX.Element | null) => void;
  setGracePeriod: (gracePeriodInMillis: number) => void;
};

const Context = createContext<ModalState>({} as ModalState);

export function useModalMaker() {
  const context = useContext(Context);
  return context;
}

export default function ModalProvider({ children }: { children: ReactNode }) {
  const [element, setElement] = useState<JSX.Element | null>(null);
  const [lastMills, setLastMillis] = useState<number>(Date.now());
  const value = useRef<ModalState>({
    setModal: (element) => {
      setElement(element);
    },
    setGracePeriod: (mills) => {
      setLastMillis(Date.now() + mills);
    },
  });

  return (
    <Context.Provider value={value.current}>
      {children}
      {element && (
        <div
          className="h-screen fixed w-screen bg-brand-100 bg-opacity-20 backdrop-blur-[1px] top-0 left-0 flex justify-center items-center"
          onClick={(e) => {
            if (Date.now() >= lastMills) setElement(null);
          }}
        >
          <div
            className="bg-white rounded shadow-md min-w-[300px] flex justify-center relative py-4 "
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {element}
            <span
              className="absolute top-1 right-2 rounded-full hover:bg-gray-100 transition-colors hover:cursor-pointer p-1"
              onClick={(e) => {
                setElement(null);
              }}
            >
              <Close />
            </span>
          </div>
        </div>
      )}
    </Context.Provider>
  );
}
