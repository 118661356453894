function Footer({ position }: { position?: string }) {
  return (
    <div className={`w-full ${position || "absolute"} bottom-0`}>
      <p className="text-sm text-gray-900 py-2 sm:px-10 text-center">
        Copyright &copy; {new Date().getFullYear()} MetronMind All rights
        reserved.{" "}
        <a
          href="http://www.metronmind.com/privacypolicy"
          className="font-semibold hover:underline print:hidden"
          target={"_blank"}
          rel="noreferrer"
        >
          Privacy Policy
        </a>
        {" | "}
        <a
          href="https://www.metronmind.com/vhsvlasdetails"
          className="font-semibold hover:underline print:hidden"
          target={"_blank"}
          rel="noreferrer"
        >
          Algorithm Validation Details
        </a>
      </p>
    </div>
  );
}
export default Footer;
