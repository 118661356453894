import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createTheme, Theme, ThemeProvider } from "@aws-amplify/ui-react";

import AuthProvider from "./helpers/AuthProvider";
import ModalProvider from "./helpers/ModalProvider";
import LanguageProvider from "./helpers/LanguageProvider";

function App() {
  const themeOptions: Theme = {
    name: "MainTheme",
    tokens: {
      colors: {
        brand: {
          primary: {
            "10": { value: "#D6F1FF" },
            "20": { value: "#66D1FF" },
            "40": { value: "#0099E5" },
            "60": { value: "#0A69C7" },
            "80": { value: "#0066CC" },
            "90": { value: "#003366" },
            "100": { value: "#001A33" },
          },
        },
      },
    },
  };
  const theme = createTheme(themeOptions);
  return (
    <ThemeProvider theme={theme}>
      <LanguageProvider>
        <AuthProvider>
          <ModalProvider>
            <div className="min-h-screen bg-[#E4EAF1]">
              <Outlet />
              <ToastContainer
                position="bottom-center"
                autoClose={5000}
                closeOnClick
                pauseOnHover
                theme="colored"
              />
            </div>
          </ModalProvider>
        </AuthProvider>
      </LanguageProvider>
    </ThemeProvider>
  );
}

export default App;
