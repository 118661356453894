import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useFormikContext } from "formik";
import CustomTextField from "../../helpers/CustomTextField";
import { useLanguage } from "../../helpers/LanguageProvider";
import { VHSFormValues } from "../../types";
import ImageDragAndDrop from "./ImageDragAndDrop";

function ImageInfo() {
  const formik = useFormikContext<VHSFormValues>();
  const pack = useLanguage().main.imageUpload;
  return (
    <div className="w-full sm:flex-1 bg-neutral-100 border border-neutral-300 h-80 rounded-sm">
      <h4 className="text-center font-sans text-lg font-semibold my-1">
        {pack.header}
      </h4>
      <ImageDragAndDrop />
      <div className="w-full flex justify-center mt-8">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={formik.values.date}
            maxDate={new Date()}
            onChange={(value) => {
              formik.setFieldValue("date", value);
            }}
            label={pack.dateTakenLabel}
            renderInput={(params) => (
              <CustomTextField
                size="small"
                helperText={
                  Boolean(formik.errors.date) && formik.touched.date
                    ? formik.errors.date
                    : null
                }
                {...params}
                error={
                  params.error ||
                  (Boolean(formik.errors.date) && formik.touched.date)
                }
                sx={{ maxWidth: 300 }}
                onBlur={(e) => {
                  formik.setFieldTouched("date");
                }}
              />
            )}
          />
        </LocalizationProvider>
      </div>
    </div>
  );
}
export default ImageInfo;
