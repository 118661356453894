import RedeemCoupon from "./RedeemCoupon";
import UpgradeAccount from "./UpgradeAccount";
import { toast } from "react-toastify";
import { useAuthState } from "../../helpers/AuthProvider";

type Props = {};
function NoCouponsScreen({}: Props) {
  const { refreshUser } = useAuthState();
  return (
    <div className="flex flex-row py-4 px-16 flex-wrap gap-x-8 gap-y-1">
      <div className="w-full sm:flex-1 bg-neutral-100 border border-neutral-300 h-40 rounded-sm pt-2 px-4">
        <RedeemCoupon
          onSuccess={() => {
            refreshUser();
            toast.success("Successfully redeemed coupon!");
          }}
        />
      </div>
      <p className="flex-initial grow-0 place-self-center justify-self-center mx-auto">
        OR
      </p>
      <div className="w-full sm:flex-1 bg-neutral-100 border border-neutral-300 h-fit rounded-sm pt-2 px-4 pb-4">
        <UpgradeAccount />
      </div>
    </div>
  );
}
export default NoCouponsScreen;
