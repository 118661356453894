import { Backdrop, CircularProgress } from "@mui/material";
import { useAuthState } from "../../helpers/AuthProvider";
import LoginForm from "../AuthScreen/LoginForm";
import VHSForm from "./VHSForm";

function Main() {
  const { loading, user } = useAuthState();
  if (loading)
    return (
      <Backdrop open={true}>
        <CircularProgress />
      </Backdrop>
    );
  if (!user) return <LoginForm />;
  return (
    <div>
      <VHSForm />
    </div>
  );
}
export default Main;
