import React from 'react'

type Props = {}

function ConfirmationPage({}: Props) {
  return (
    <div className="w-screen h-screen flex flex-col justify-center items-center">
      <div className="bg-white max-w-sm p-3 rounded-md shadow-md space-y-1">
        <h2 className='font-semibold text-lg'>Account created</h2>
        <p>Congratulations, you have successfully created an account with MetronMind. You should have received a confirmation email, please check your inbox to confirm your account.</p>
        <p>Once confirmed, return <a href="/" className="text-brand-80">home</a> to sign in.</p>
      </div>
    </div>
  )
}

export default ConfirmationPage