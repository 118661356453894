import {
  ApiKey,
  CompletedJob,
  CompletedJobError,
  Error,
  IEConfig,
  InProgressJob,
  VHSFormValues,
} from "./../types";
import axios from "axios";
import config from "../config";
import { User } from "../helpers/AuthProvider";

const url = config.apiUrl;

/**
 * Will use the MetronIQ API to upload this image.
 * @param file the image to upload.
 * @return {string} the ID of the input.
 */
export async function createInput(file: File, token: string): Promise<string> {
  try {
    const uploadRequest = await axios.post(
      url + "/inputs",
      await file.arrayBuffer(),
      {
        headers: {
          "content-type": file.name.endsWith(".dcm")
            ? "application/dicom"
            : file.type,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return uploadRequest.data;
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function createJob(
  values: VHSFormValues,
  input: string,
  token: string
): Promise<string> {
  const newDate = values.date!;
  const offset = newDate.getTimezoneOffset() * 60 * 1000;
  values.date = new Date(values.date!.getTime() + 1000 * 60 * 60 * 7 - offset);
  try {
    const additionalInfo = {
      owner: values.owner,
      animal: values.animal,
      breed: values.breed || undefined,
      patientId: values.patientId || undefined,
      title: "Title$OAD",
      date: values.date.toISOString(), //Adjustment for california.
    };
    const config: IEConfig = {
      species: "canine",
      modality: "radiograph",
      action: "bIjob",
      output: ["report", "graphic1", "image", "data"],
      additionalInfo,
    };
    const metadata = { ...values, file: undefined };
    const createRequest = await axios.post(
      url + "/jobs",
      JSON.stringify({
        config,
        inputs: [input],
        public: true,
        metadata,
      }),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return createRequest.data;
  } catch (_err) {
    const err = _err as any;
    if (err.response) {
      const errors: Error[] = err.response.data;

      // Error if out of codes.
      if (errors.length === 1 && errors[0].code === 10016) {
        return Promise.reject("Out of coupon uses.");
      }
      return Promise.reject(
        errors.map((error) => error.message || "").join(",")
      );
    }
    return Promise.reject(err);
  }
}

export async function getJob(
  jobId: string,
  token?: string
): Promise<InProgressJob | CompletedJob | CompletedJobError> {
  try {
    const getJobRequest = await axios.get(url + `/jobs/${jobId}`, {
      headers: {
        "Content-Type": "application/json",
        ...(token ? { Authorization: `Bearer ${token}` } : {}), // Little hack to only include api key header if one is defined.
      },
    });
    return getJobRequest.data;
  } catch (_err) {
    const err = _err as any;
    if (err.response) {
      const data = err.response.data as Array<Error>;
      return Promise.reject(
        data.map((error: any) => error.message || "").join(",")
      );
    }
    return Promise.reject(err);
  }
}

export async function getInput(inputId: string, token?: string) {
  const res = await axios.get(config.apiUrl + `/inputs/${inputId}`, {
    headers: {
      "Content-Type": "application/json",
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
    },
  });
  return res.data as string;
}

export async function getJobs(
  token: string
): Promise<Array<InProgressJob | CompletedJob | CompletedJobError>> {
  try {
    const getJobRequest = await axios.get(url + `/jobs`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return getJobRequest.data;
  } catch (_err) {
    const err = _err as any;
    if (err.response) {
      const data = err.response.data as Array<Error>;
      return Promise.reject(
        data.map((error: any) => error.message || "").join(",")
      );
    }
    return Promise.reject(err);
  }
}

export async function sendEmail(
  jobId: string,
  values: { email: string; subject: string; message?: string },
  token?: string
): Promise<ApiKey> {
  try {
    const getKeyRequest = await axios.post(
      url + `/jobs/${jobId}/share`,
      JSON.stringify({
        recipientEmail: values.email,
        subject: values.subject,
        message: values.message || undefined,
        attachReport: false,
      }),
      {
        headers: {
          "Content-Type": "application/json",
          ...(token ? { Authorization: `Bearer ${token}` } : {}),
        },
      }
    );
    return getKeyRequest.data;
  } catch (_err) {
    const err = _err as any;
    if (err.response) {
      const data = err.response.data as Array<Error>;
      return Promise.reject(
        data.map((error: any) => error.message || "").join(",")
      );
    }
    return Promise.reject(err);
  }
}

export async function refresh(userId: string, refreshToken: string) {
  const res = await axios.post(config.apiUrl + "/auth/refresh", {
    refresh_token: refreshToken,
    user_id: userId,
  });

  const data = res.data as {
    access_token: string;
    refresh_token: string;
    user_id: string;
  };
  return data;
}

export async function login(email: string, password: string) {
  const res = await axios.post(
    config.apiUrl + "/auth/login",
    {
      email,
      password,
    },
    { headers: { "content-type": "application/json" } }
  );
  const data = res.data as {
    access_token: string;
    refresh_token: string;
    user_id: string;
  };
  return data;
}

export async function logout(userId: string, refreshToken: string) {
  await axios.post(config.apiUrl + "/auth/logout", {
    user_id: userId,
    refresh_token: refreshToken,
  });
}

export async function getUserInfo(token: string): Promise<User> {
  const res = await axios.get(config.apiUrl + "/auth/user", {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  const user = res.data as User;
  return user;
}

export async function redeemCoupon(token: string, code: string) {
  const res = await axios.post(
    config.apiUrl + "/coupons/redeem",
    { code },
    {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
}

export async function createDemoUser(email: string, password: string) {
  return axios.post(
    config.apiUrl + "/bi/signup",
    {
      email,
      password,
    },
    {
      headers: {
        "content-type": "application/json",
      },
    }
  );
}

export async function resubmitPoints(
  token: string,
  job: string,
  points: number[]
) {
  return axios.post(
    config.apiUrl + "/jobs/" + job + "/resubmit",
    [
      {
        field: "opoints",
        value: points,
      },
      {
        field: "measname",
        value: "BIjob",
      },
    ],
    {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
}
