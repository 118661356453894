import { Edit } from "@mui/icons-material";
import { Alert, Button, Fab, Tooltip } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import config from "../../config";
import { useAuthState } from "../../helpers/AuthProvider";
import EnlargableImage from "../../helpers/EnlargableImage";
import { useLanguage } from "../../helpers/LanguageProvider";
import { useModalMaker } from "../../helpers/ModalProvider";
import { useWindowSize } from "../../helpers/useWindowSize";
import { getInput } from "../../logic/APIInterface";
import { CompletedJob } from "../../types";
import PointEditor from "./PointEditor";
import { VHSVLASSchema } from "./PointEditorSchema";
import ReportEditor from "./ReportEditor/ReportEditor";

type Props = {
  job: CompletedJob;
  myJob: boolean;
  addFab: (fab: JSX.Element) => void;
};

export function getDate(now: Date) {
  now = new Date(now.getTime() - 3 * 60 * 60 * 1000+now.getTimezoneOffset()*60*1000);
  console.log(now);
  return `${now.getDate()}-${now.getMonth() + 1}-${now.getFullYear()}`;
}

export function getVHSVLASResult(
  vhs: number,
  vlas: number
): "normal" | "borderline" | "enlarged" {
  if (vhs < 10.5) {
    return "normal";
  } else if (vhs >= 11.5 && vlas >= 3.0) {
    return "enlarged";
  } else {
    return "borderline";
  }
}

function pointsFromStringList(list: string[]) {
  const pntCount = Math.floor(list.length / 2);

  return Array.from(Array(pntCount).keys()).map((_, index) => {
    const indexX = index * 2;
    const indexY = index * 2 + 1;
    return { x: parseInt(list[indexX]), y: parseInt(list[indexY]) };
  });
}

function ReportViewer({ job, addFab }: Props) {
  const [inputImageUrl, setInputImageUrl] = useState<string | null>(null);

  const { token } = useAuthState();
  console.log(job);
  // Effect to get inputImageUrl from input.
  useEffect(() => {
    getInput(job.inputs[0], token || undefined).then((url: string) => {
      setInputImageUrl(url);
    });
  }, [job, token]);

  const metadata = job.metadata as {
    animal: string;
    breed: string;
    owner: string;
    patientId: string;
    date: string;
  };
  const data = job.output.data as any;
  const { setModal } = useModalMaker();
  const pack = useLanguage().jobs.report;

  const vhs = parseFloat(data.Values[0]);
  const vlas = parseFloat(data.Values[5]);

  useEffect(() => {
    if (inputImageUrl && data && data.OPoints) {
      addFab(
        <Tooltip title={pack.pointEditor.fabHint}>
          <span
            onClick={() => {
              setModal(
                <ReportEditor
                  points={pointsFromStringList(data.OPoints as string[])}
                  imageUrl={inputImageUrl}
                  schema={VHSVLASSchema}
                  jobId={job.id}
                />
              );
            }}
          >
            <Fab color="primary">
              <Edit fontSize="large" sx={{ color: "white" }} />
            </Fab>
          </span>
        </Tooltip>
      );
    }
  }, [inputImageUrl]);

  const result = getVHSVLASResult(vhs, vlas);
  let resultSeverity: "info" | "error" | "warning" = "info";
  let resultText = "";
  if (result === "normal") {
    resultText = pack.analysis.blobNormal;
  } else if (result === "enlarged") {
    resultText = pack.analysis.blobEnlarged;
    resultSeverity = "error";
  } else {
    resultText = pack.analysis.blobBorderline;
    resultSeverity = "warning";
  }

  return (
    <div className="bg-white container drop-shadow-md print:drop-shadow-none flex flex-col w-screen md:w-[85vw] items-center">
      <div className="flex flex-row flex-wrap items-center justify-start w-full">
        <div className="h-24 flex-none ml-4 w-full sm:w-fit">
          <img
            src="/assets/bi_logo.jpg"
            alt="BI logo"
            className="object-contain w-full h-full box-border"
          />
        </div>
        <h1 className="text-center font-medium text-5xl text-brand-100 flex-auto min-h-[60px]">
          {pack.header}
        </h1>
      </div>
      <span className="w-full h-1 bg-brand-90"></span>
      <div className="flex flex-row flex-wrap justify-evenly w-full gap-2">
        <p className="w-fit text-xl">
          {pack.ownerLabel}
          {metadata.owner}
        </p>
        <p className="w-fit text-xl">
          {pack.animalLabel}
          {metadata.animal}
          {metadata.patientId ? ` (${metadata.patientId})` : ""}
        </p>
        <p className="w-fit text-xl">
          {pack.dateLabel}
          {getDate(new Date(metadata.date))}
        </p>
      </div>
      <div className="flex flex-row flex-wrap justify-center gap-x-8 px-8 py-4 w-full">
        <div className="w-full sm:flex-1">
          <h2 className="text-xl font-semibold">{pack.results.header}</h2>
          <p className="leading-5 mt-2 mb-4">{pack.results.blob1}</p>
          {job.output.image ? (
            <EnlargableImage src={job.output.image} alt="VHS/VLAS" />
          ) : (
            <p>{pack.noImage}</p>
          )}
        </div>
        <div className="w-full sm:flex-1">
          <h2 className="text-xl font-semibold">{pack.analysis.header}</h2>
          <p
            className="leading-5 my-2"
            dangerouslySetInnerHTML={{ __html: pack.analysis.blob1(vhs, vlas) }}
          ></p>
          {job.output.graphic1 ? (
            <EnlargableImage
              src={job.output.graphic1}
              alt="vhs/vlas histogram"
            />
          ) : (
            <p>{pack.noImage}</p>
          )}

          <Alert severity={resultSeverity} sx={{ mt: 1 }}>
            {resultText}
          </Alert>
        </div>
      </div>
      <p className="text-center text-sm tracking-wide mb-1  print:m-0 print:w-fit print:fixed print:bottom-1 print:right-0">
        {pack.poweredBy}{" "}
        <a
          href="https://www.metronmind.com"
          className="text-blue-900 hover:text-blue-700 tracking-normal"
        >
          MetronMind.com
        </a>
      </p>
    </div>
  );
}
export default ReportViewer;
