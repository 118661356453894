import axios from "axios";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { decodeToken, isExpired } from "react-jwt";
import { number } from "yup";
import config from "../config";
import {
  getUserInfo,
  login,
  logout,
  redeemCoupon,
  refresh,
} from "../logic/APIInterface";

export type User = {
  uid: string;
  email: string;
  uses: { remaining: number; jobs: string[] };
};

type AuthState = {
  user: User | null;
  loading: boolean;
  login: (email: string, password: string) => Promise<boolean>;
  logout: () => void;
  refreshUser: () => void;
  redeem: (code: string) => Promise<void>;
  token: string | null;
};

const Context = createContext<AuthState>({} as AuthState);

export function useAuthState() {
  const context = useContext(Context);
  return context;
}

// Flow:
// Attempt a refresh ->
// success: save access token in state and setup auto log out at expire time.
// failure: prompt login screen, user logs in -> same as refresh success

type RefreshBlob = {
  token: string;
  uid: string;
  access: string;
};

const secretLocalStorageName = "ganalytics_ssl";

export default function AuthProvider({ children }: { children: ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  function getSavedRefreshBlob() {
    const text = window.localStorage.getItem(secretLocalStorageName);
    if (text) {
      return JSON.parse(text) as RefreshBlob;
    } else {
      return null;
    }
  }

  function saveRefreshBlob(blob: RefreshBlob) {
    window.localStorage.setItem(secretLocalStorageName, JSON.stringify(blob));
  }

  async function refreshTokens(blob: RefreshBlob) {
    const newData = await refresh(blob.uid, blob.token);
    const newBlob = {
      access: newData.access_token,
      token: newData.refresh_token,
      uid: newData.user_id,
    };
    saveRefreshBlob(newBlob);
    await updateComponentStateFromBlob(newBlob.access);
  }

  async function updateComponentStateFromBlob(token: string) {
    setAccessToken(token);
    const user = await getUserInfo(token);
    setUser(user);
  }

  function clearAllAuth() {
    setUser(null);
    setAccessToken(null);
    window.localStorage.removeItem(secretLocalStorageName);
  }

  // Idea: check if we currently have auth info saved, if so then check if it is expired. If expired, then try to refresh. If refresh fails, clear all auth.
  // If it was not expired, then just use it but set up a timeout to refresh if need be.
  useEffect(() => {
    const blob = getSavedRefreshBlob();
    if (blob) {
      const expired = isExpired(blob.access);
      if (expired) {
        refreshTokens(blob)
          .then(() => {})
          .catch(() => {
            clearAllAuth();
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        const expirationTimeMillis =
          (decodeToken(blob.access) as any).exp * 1000;
        setTimeout(() => {
          setLoading(true);
          refreshTokens(blob)
            .then(() => {})
            .catch(() => {
              clearAllAuth();
            })
            .finally(() => {
              setLoading(false);
            });
        }, Math.max(2000, expirationTimeMillis - Date.now() - 60000));
        updateComponentStateFromBlob(blob.access).then(() => {
          setLoading(false);
        });
      }
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <Context.Provider
      value={{
        loading,
        user,
        login: async (email, password) => {
          return new Promise((resolve, _) => {
            login(email, password)
              .then(async (data) => {
                const blob = {
                  token: data.refresh_token,
                  access: data.access_token,
                  uid: data.user_id,
                };
                saveRefreshBlob(blob);
                updateComponentStateFromBlob(blob.access);
                resolve(true);
              })
              .catch(() => {
                resolve(false);
              });
          });
        },
        token: accessToken,
        logout: () => {
          const blob = getSavedRefreshBlob();
          if (blob) logout(blob.uid, blob.token);
          clearAllAuth();
        },
        refreshUser: () => {
          const blob = getSavedRefreshBlob();
          if (!blob) {
            clearAllAuth();
            return;
          }
          updateComponentStateFromBlob(blob.access);
        },
        redeem: async (code) => {
          return new Promise((resolve, reject) => {
            if (!accessToken) return reject("No access token found");
            redeemCoupon(accessToken, code)
              .then((res) => {
                resolve();
              })
              .catch((err) => {
                reject(err.response.data);
              });
          });
        },
      }}
    >
      {children}
    </Context.Provider>
  );
}
